
import { LogoutOutlined, UserOutlined, NotificationOutlined } from '@ant-design/icons-vue';
import { logout } from "@/api/service/login";
import { Vue, Options } from 'vue-class-component';
import {Action, Getter, Mutation} from "vuex-class";
import * as types from "@/store/constants/user_types";
import * as filter_types from "@/store/constants/filter_types";
import Toast from "@/utils/toast";
import {RESET_STORE} from "@/store/constants/user_types";
import { resetRouter } from "@/router";
import NotificationModal from "@/components/NotificationModal.vue";
import {Layout, Badge, Menu, Image, Avatar, Dropdown} from "ant-design-vue"
import {isMobileDevice} from "@/utils/utils";

@Options({
  components: {
    LogoutOutlined,
    UserOutlined,
    NotificationOutlined,
    NotificationModal,
    ALayout: Layout,
    ALayoutHeader: Layout['Header'],
    ABadge: Badge,
    AImage: Image,
    AMenu: Menu,
    AMenuItem: Menu['Item'],
    AAvatar: Avatar,
    ADropdownButton: Dropdown['Button']
  }
})
export default class Home extends Vue {
  @Getter(types.GETTER_USERNAME)
  private username: any;
  @Mutation(RESET_STORE)
  private resetStore: any;
  @Getter(types.GETTER_LEVEL)
  private roleLevel: any;
  @Action(types.UPDATE_APP_LIST)
  private updateAppList: any;
  @Getter(types.GETTER_APP_LIST)
  private appList: any;
  @Action(types.UPDATE_USER_LIST)
  private updateUserList: any;
  @Action(types.UPDATE_CATEGORY_LIST)
  private updateCategoryList: any;
  @Action(filter_types.UPDATE_NOTIFICATION_LIST)
  private updateNotificationList: any;
  @Getter(filter_types.GETTER_NOTIFICATION_LIST)
  private notificationList: any;

  private currentTab: any = [];
  private modalShow = false;
  private rightWidth = 0;

  get isMobile() {
    return isMobileDevice();
  }

  get menuList() {
    const menu = [{
      id: 1,
      name: "应用管理",
      href: "/home/app"
    }];
    const setMenu = {
      id: 2,
      name: "设置",
      href: "/home/setting"
    };
    // const testMenu = {
    //   id: 3,
    //   name: "测试",
    //   href: "/home/test"
    // }
    if (this.$route.name !== "403") {
      parseInt(this.roleLevel) === 100 && menu.push(setMenu);
    } else {
      return [];
    }
    // 测试环境增加test page
    // if (
    //     process.env.VUE_APP_ENV_NAME === "staging" ||
    //     process.env.VUE_APP_ENV_NAME === "development"
    // ) {
    //   menu.push(testMenu);
    // }
    return menu;
  }

  private toggleModalState() {
    this.modalShow = !this.modalShow;
    this.rightWidth = (this.$refs.dropdown as any).$el.offsetWidth + 20;
  }

  private closeInnerModal() {
    this.modalShow && (this.modalShow = false);
  }

  private async handleMenuClick() {
    try {
      await logout();
      this.resetStore();
      this.$router.replace("/login");
      // logout重置路由
      setTimeout(() => {
        resetRouter();
      }, 0)
    } catch (e) {
      Toast.show("error", e);
    }
  }
  private async refreshBaseMsg() {
    console.log("refreshBaseMsg");
    parseInt(this.roleLevel) === 100 && this.updateUserList({
      refresh: true
    });
    parseInt(this.roleLevel) === 100 && this.updateCategoryList();
    this.updateNotificationList({
      state: "NOT_READ"
    });
  }
  created() {
    const currentMenu = this.menuList.find((v) => this.$route.fullPath.includes(v.href));
    this.currentTab = currentMenu ? [currentMenu.id] : [1];
    if (this.$route.name !== "403") {
      this.refreshBaseMsg();
    }
  }
}

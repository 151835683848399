import {Router, store} from "@/main";
import * as types from "@/store/constants/user_types";
import { shouldRedirect, redirectPrivate } from "@/utils/redirect";

export const initDefaultRouter = async () => {
    if (shouldRedirect()) {
        await redirectPrivate();
    } else {
        await (store as any).restored;
        // 如果是发布页面，提前校验token
        if (location.pathname && location.pathname.includes("publish")) {
            await store.dispatch(types.CHECK_TOKEN);
        }
        if (store.getters[types.GETTER_USERNAME] && store.getters[types.GETTER_TOKEN]) {
            Router.addRoute({
                path: "/",
                redirect: "/home/app"
            });
        } else {
            Router.addRoute({
                path: "/",
                redirect: "/login"
            });
        }
    }
}

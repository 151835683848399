import {message} from "ant-design-vue";
import {isString, throttle} from "@/utils/utils";

class Toast {
    private showMessageDebounced: any
    constructor() {
        this.showMessageDebounced = throttle(this.showMessage, 3000, true);
    }
    private showMessage(type: "error" | "warning" | "success" = "error", msg: any) {
        message[type](isString(msg) ? msg : "网络不太给力~");
    }

    public show(type: "error" | "warning" | "success" = "error", msg: any) {
        console.log("show time", new Date().getTime());
        this.showMessageDebounced(type, msg);
    }
}

export default new Toast();

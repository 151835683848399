import {BaseApi} from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";

export const uploadCertification = async (file, callback) => {
  try {
    const formData = new FormData();
    file && formData.append("file", file);
    const result = await BaseApi.post(PATH_URL.UPLOAD_CERTIFICATION, formData, {
      timeout: 300000,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const upLoadProgress = progressEvent.loaded / progressEvent.total * 100;
          console.log("upLoadProgress", upLoadProgress);
          callback(upLoadProgress * 0.8);
        }
      }
    });
    callback(100);
    return result ? result.data : [];
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getCertList = async () => {
  try {
    const result = await BaseApi.get(PATH_URL.CERTIFICATION_LIST);
    return result ? result.data : [];
  } catch (e) {
    return Promise.reject(e);
  }
}

export const reSignificate = async (certId, appVersionId) => {
  try {
    const result = await BaseApi.post(PATH_URL.RESIGN, {
      certId,
      appVersionId
    });
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

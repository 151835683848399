
import {Options, Vue, Watch} from "vue-property-decorator";
import Toast from "@/utils/toast";
import {Action, Getter, Mutation} from "vuex-class";
import {
  GETTER_APP_LIST,
  GETTER_CATEGORY_LIST,
  GETTER_CURRENT_APP_ID,
  GETTER_LEVEL,
  GETTER_USER_LIST,
  UPDATE_CURRENT_APP_ID
} from "@/store/constants/user_types";
import {
  SearchOutlined,
  DatabaseOutlined,
  AppstoreOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
  RightOutlined,
  DownOutlined,
  DownloadOutlined,
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons-vue";
import {
  UPDATE_FILTER,
  GETTER_FILTER,
  GETTER_PLATFORM_COUNTS,
  GETTER_APP_EDITORS,
  GETTER_NATIVE_READ_LIST,
  READ_NOTIFICATION,
  UPDATE_NATIVE_READ_LIST,
  UPDATE_COLLAPSED,
  GETTER_STATE_COLLAPSED
} from "@/store/constants/filter_types";
import {getRandomNum, getRanNumChat, isMobileDevice} from "@/utils/utils";
import * as types from "@/store/constants/user_types";
import AppModal from "@/components/AppModal.vue";
import {getDefaultSrc} from "@/utils/common";
import {Layout, Button, Checkbox, Input, Image, Spin, Badge, Modal} from "ant-design-vue";

@Options({
  components: {
    DatabaseOutlined,
    AppstoreOutlined,
    SearchOutlined,
    SwapLeftOutlined,
    SwapRightOutlined,
    RightOutlined,
    DownOutlined,
    DownloadOutlined,
    PlusOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    AppModal,
    ALayout: Layout,
    ALayoutSider: Layout["Sider"],
    ALayoutContent: Layout["Content"],
    AButton: Button,
    ACheckbox: Checkbox,
    AInput: Input,
    AImage: Image,
    ASpin: Spin,
    ABadge: Badge,
    AModal: Modal
  }
})

export default class AppHome extends Vue {
  @Getter(GETTER_CATEGORY_LIST)
  private categoryList: any;
  @Getter(GETTER_LEVEL)
  private roleLevel: any;
  @Getter(GETTER_CURRENT_APP_ID)
  private currentAppId: any;
  @Getter(GETTER_APP_LIST)
  private appList: any;
  @Mutation(UPDATE_CURRENT_APP_ID)
  private updateCurrentAppId: any;
  @Getter(GETTER_USER_LIST)
  private userList: any;
  @Action(types.UPDATE_USER_LIST)
  private updateUserList: any;
  @Action(types.UPDATE_APP_LIST)
  private updateAppList: any;
  @Mutation(UPDATE_FILTER)
  private updateFilter: any;
  @Getter(GETTER_FILTER)
  private getterFilters: any;
  @Getter(GETTER_PLATFORM_COUNTS)
  private getterPlatformCounts: any;
  @Getter(GETTER_APP_EDITORS)
  private getterAppEditors: any;
  @Getter(GETTER_NATIVE_READ_LIST)
  private nativeReadList: any;
  @Action(READ_NOTIFICATION)
  private readNotification: any;
  @Mutation(UPDATE_NATIVE_READ_LIST)
  private updateNativeReadList: any;
  @Mutation(UPDATE_COLLAPSED)
  private updateCollapsed: any;
  @Getter(GETTER_STATE_COLLAPSED)
  private stateCollapsed: any;

  private btn_collpase = false;
  private filter_show = false;
  private order_show = false;
  private search_text = "";
  private visible = false;
  private modalTitle = "";
  private edit_user_ids: string[] = [];
  private download_user_ids: string[] = [];
  private spinning = false;
  private collect_checked = false;
  private toggleType: "developer" | "category" = "developer";
  private phoneModalVisible = false;
  private phoneModalTitle = '';

  private orderList = [{
    title: '最近更新时间',
    sort: "DESC",
    key: "update_time"
  }, {
    title: '最近更新时间',
    sort: "ASC",
    key: "update_time"
  }, {
    title: '名称',
    sort: "DESC",
    key: "app_name"
  }, {
    title: '名称',
    sort: "ASC",
    key: "app_name"
  }, {
    title: '新建时间',
    sort: "DESC",
    key: "create_time"
  }, {
    title: '新建时间',
    sort: "ASC",
    key: "create_time"
  }]


  get siderWidth() {
    return isMobileDevice() ? this.stateCollapsed ? 260 : 51 : 260;
  }

  get leftMargin() {
    return isMobileDevice() ? 51 : 260;
  }

  get isMobile() {
    return isMobileDevice();
  }

  private formState: any = {
    appType: 'iOS'
  };

  // 判断是否满足编辑权限
  get satisfiedEditPower() {
    return this.roleLevel === 100;
  }

  get maxHeight() {
    return {
      maxHeight: document.body.clientHeight - 497 + "px"
    }
  }

  get app() {
    return this.appList.find((v: any) => v.appId === this.currentAppId);
  }

  get isUDIDPage() {
    return this.$route.fullPath.includes("/home/udid");
  }

  get isCategoryPage() {
    return this.$route.fullPath.includes("/home/category");
  }

  get isAppPage() {
    return this.$route.fullPath.includes("/home/app")
  }

  get showFilterMark() {
    return (
        this.getterFilters.appType !== "全部" ||
        this.getterFilters.categoryId !== -1 ||
        this.getterFilters.userId !== -1
    );
  }

  get showSearchMark() {
    return !!this.search_text;
  }

  get showCollectMark() {
    return this.getterFilters.viewCollect;
  }

  get all_count() {
    return this.getterPlatformCounts.length > 0 ? this.getterPlatformCounts[0].count : 0;
  }

  @Watch("getterFilters", {immediate: true, deep: true})
  private filterChange() {
    this.fetchAppList();
  }


  private getDefault(appType: "iOS" | "Android") {
    return getDefaultSrc(appType);
  }

  private toggleBtnState() {
    this.btn_collpase = !this.btn_collpase;
    this.filter_show = false;
    this.order_show = false;
  }

  private toggleCollapsed() {
    this.updateCollapsed(!this.stateCollapsed);
  }

  private toggleState() {
    if (this.toggleType === "developer") {
      this.toggleType = "category";
    } else {
      this.toggleType = "developer";
    }
  }

  private phoneModalOk() {
    this.phoneModalVisible = false;
  }

  private phoneModalCancel() {
    if (this.phoneModalTitle === "搜索") {
      this.search_text = "";
    }
    this.phoneModalVisible = false;
    this.phoneModalTitle = "";
  }

  private clickFilter(item: any) {
    // this.filter_option = item;
    this.updateFilter({
      appType: item
    })
    this.filter_show = false;
    if (this.isMobile) {
      this.phoneModalVisible = false;
    }
    // this.fetchAppList();
  }

  private clickCollect(e: any) {
    this.updateFilter({
      viewCollect: e.target.checked
    })
    this.order_show = false;
  }

  private filterOrder() {
    if (this.isMobile) {
      this.phoneModalVisible = true;
      this.phoneModalTitle = '排序'
      console.log("filterOrder", this.phoneModalTitle);
    } else {
      this.order_show = !this.order_show;
      this.filter_show = false;
    }
  }

  private filterType() {
    if (this.isMobile) {
      this.phoneModalVisible = true;
      this.phoneModalTitle = '筛选'
    } else {
      this.filter_show=!this.filter_show;
      this.order_show=false;
    }
  }

  private clickOrder(item: any) {
    // this.order_option=item;
    this.updateFilter({
      orderByColumn: item.key,
      sort: item.sort
    })
    this.order_show = false;
    if (this.isMobile) {
      this.phoneModalVisible = false;
    }
    // this.fetchAppList();
  }

  private clickCategory(id: any) {
    this.updateFilter({
      categoryId: id,
      userId: -1
    })
    if (this.isMobile) {
      this.phoneModalVisible = false;
    }
  }

  private clickEditor(id: any) {
    console.log("clickEditor", id);
    this.updateFilter({
      userId: id,
      categoryId: -1
    })
    if (this.isMobile) {
      this.phoneModalVisible = false;
    }
  }

  private async fetchAppList() {
    try {
      this.spinning = true;
      await this.updateAppList(this.getterFilters);
      this.spinning = false
    } catch (e) {
      this.spinning = false
      Toast.show('error', e);
    }
  }

  private reset() {
    this.formState = {
      appType: 'iOS'
    };
    this.visible = false;
    this.modalTitle = "";
  }

  private filterAppList(search_name: string) {
    return search_name ? this.appList.filter(
        (v: any) => v.appName.toLowerCase().includes(
            search_name.toLowerCase()
        )
    ) : this.appList;
  }

  // private calcAppCountByPlatform(platform: string) {
  //   if (platform === "全部") {
  //     return this.totalCount;
  //   } else {
  //     return platform === "iOS" ? this.iosCount : this.androidCount;
  //   }
  // }

  private async toPublish() {
    if (!this.currentAppId || !this.app) {
      Toast.show(
          "warning",
          this.satisfiedEditPower ? "请先添加一个应用" : "请联系管理员先创建一个应用"
      );
    } else {
      const {href} = this.$router.resolve({
        name: "publish"
      });
      window.open(href, "_blank");
    }
  }

  private async addApp() {
    const formState = {
      path: getRanNumChat(4),
      publicPath: getRanNumChat(4),
      password: getRandomNum(6)
    };
    this.formState = {...formState, ...this.formState};
    this.visible = true;
    this.modalTitle = "添加应用";
    this.btn_collpase = false;
    this.edit_user_ids = [];
    this.download_user_ids = [];
    if (this.userList.length === 0 && this.satisfiedEditPower) {
      await this.updateUserList({
        refresh: true
      });
    }
  }

  private toUDID() {
    this.$router.push({
      name: "udid"
    })
    if (this.stateCollapsed) {
      this.updateCollapsed(false);
    }
  }

  private toCategory() {
    this.$router.push({
      name: "category"
    })
    if (this.stateCollapsed) {
      this.updateCollapsed(false);
    }
  }

  private chooseApp(item: any) {
    // 如果有未读的消息并且点击的是当前的
    if (item.unreadMessage && (item.appId === this.currentAppId)) {
      this.readNotification({appId: item.appId});
      this.updateNativeReadList(item.appId);
    }
    this.updateCurrentAppId(item.appId);
    this.$router.push({
      name: "app"
    })
    if (this.stateCollapsed) {
      this.updateCollapsed(false);
    }
  }

  private lazyLoading(e: any) {
    const scrollTop = e.target.scrollTop // 滚动条滚动时，距离顶部的距离
    const windowHeight = e.target.clientHeight // 可视区的高度
    const scrollHeight = e.target.scrollHeight // 滚动条的总高度
    // 滚动条到底部
    if (scrollTop + windowHeight === scrollHeight) {
      // this.page++
      // if (this.page > this.page_count) return
      // this.getData()
    }
  }

  mounted() {
    (this.$refs as any).appList.addEventListener('scroll', this.lazyLoading)
  }

  // async created() {
  //   this.fetchAppList();
  // }
}


import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons-vue';
import { sendCode, forgotPassword } from "@/api/service/login";
import { Action } from "vuex-class";
import * as types from "@/store/constants/user_types";
import {Options, Vue, Watch} from "vue-property-decorator";
import {Button, Form, Input, Typography} from "ant-design-vue";
import Toast from "@/utils/toast";
import { sendQrcodeMsg } from "@/api/service/login";
Vue.registerHooks(["beforeRouteEnter"])
@Options({
  components: {
    UserOutlined,
    LockOutlined,
    PhoneOutlined,
    ATypographyTitle: Typography['Title'],
    AForm: Form,
    AFormItem: Form['Item'],
    AButton: Button,
    AInputGroup: Input['Group'],
    AInput: Input,
    AInputPassword: Input['Password']
  }
})
export default class Browser extends Vue {
  @Action(types.LOGIN)
  private login: any;
  @Action(types.HANDLE_LOGIN_SUCCESS)
  private handleLoginSuccess: any;

  @Watch("$route", { immediate: true })
  private async WatchRouteParma(val: any) {
    if (val && val.query && val.query.code) {
      this.sendCodeToService(val.query.code);
    }
  }

  private formState: any = {};
  private countdown = 0;
  private interval: any;
  private redirectedFrom: any;
  private resetState = false;
  private btnLoading = false;
  private qrcodeState = false;

  private async onFinish() {
    try {
      const {username, password, phoneCode, phoneNumber} = this.formState
      // 区分是重置密码还是登录
      if (this.resetState) {
        await forgotPassword(password, phoneCode, phoneNumber);
        Toast.show("success", "重置成功,请登录");
        setTimeout(() => {
          this.resetState = false;
        }, 1000);
      } else {
        this.btnLoading = true;
        await this.login({
          username,
          phoneCode,
          password,
          redirectedFrom: this.redirectedFrom
        });
        this.btnLoading = false;
      }
    } catch (e) {
      Toast.show("error", e);
      this.btnLoading = false;
    }
  }

  private async getCode() {
    try {
      if (!this.resetState && !this.formState.username) {
        Toast.show("warning", "请输入用户名");
        return;
      }
      if (this.resetState && !this.formState.phoneNumber) {
        Toast.show("warning", "请输入手机号");
        return;
      }
      this.countdown = 60;
      this.interval = setInterval(() => {
        this.countdown--;
        if (this.countdown < 1) {
          clearInterval(this.interval);
        }
      }, 1000);
      await sendCode(this.resetState ? {
        phone: this.formState.phoneNumber
      } : {
        username: this.formState.username
      });
    } catch (e) {
      if (e && e.code === 304) {
        Toast.show("warning", "验证码未过期，已自动填充");
        setTimeout(() => {
          this.formState.phoneCode = e.msg;
        }, 1000)
      } else {
        Toast.show("error", e);
      }
    }
    // (this.$refs.verify_input as any).focus();
  }
  private onFinishFailed() {
    //
  }

  private validator(password: string) {
    if (password == null || password.length < 6 || password.length > 10) {
      return false;
    }
    const reg_sp = new RegExp(/^(\d)\1{5}$/);
    const str = "0123456789_9876543210";
    if (reg_sp.test(password) || str.indexOf(password)) {
      return false;
    }
    return true;
  }

  async beforeRouteEnter(to: any, from: any, next: any) {
    // console.log("to, from ", to, from);
    next((vm: any) => {
      // 记录登录的路由从哪里过来，403的page无需记录
      vm.redirectedFrom = (from.name && from.name !== "403") ? from.fullPath : "";
    });
  }

  goReset() {
    this.resetState = true;
    this.formState.password = "";
    this.formState.phoneCode = "";
  }

  async sendCodeToService(code: any) {
    const result = await sendQrcodeMsg(code);
    console.log("route result", result);
    this.handleLoginSuccess(result);
  }

  goQrcode() {
    this.qrcodeState = true;
  }

  created() {
    // setTimeout(() => {
    //   const url = "http://test.pgyerapps.com/login";
    //   new (window as any).WwLogin({
    //     "id": "qr_login",
    //     "appid": "ww8e68609ae2bed613",
    //     "agentid": "1000008",
    //     "redirect_uri": encodeURI(url),
    //     "state": "hellowecom",
    //     "href": "",
    //     "lang": "zh",
    //   });
    // }, 100)
  }
}

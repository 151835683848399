import { BaseApi } from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";

export const login = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.LOGIN, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const logout = async () => {
  try {
    await BaseApi.post(PATH_URL.LOGOUT);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const sendCode = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.PHONE_CODE, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const forgotPassword = async (newPassword, phoneCode, phoneNumber) => {
  try {
    await BaseApi.post(PATH_URL.FORGOT, {
      newPassword,
      phoneCode,
      phoneNumber
    })
  } catch (e) {
    return Promise.reject(e);
  }
}

export const bindUser = async (params) => {
  try {
    await BaseApi.post(PATH_URL.BIND_APP, params)
  } catch (e) {
    return Promise.reject(e);
  }
}

export const sendQrcodeMsg = async (code) => {
  try {
    await BaseApi.get(PATH_URL.QRCODE_PATH, {
      params: {
        code
      }
    })
  } catch (e) {
    return Promise.reject(e);
  }
}

export const checkToken = async () => {
  try {
    await BaseApi.get(PATH_URL.CHECK_TOKEN)
  } catch (e) {
    return Promise.reject(e);
  }
}

import { getAppVersionDetail } from "@/api/service/app";

export const shouldRedirect = () => {
    return (
        !location.origin.includes("localhost") &&
        !location.origin.includes("http://192.168.88.174") &&
        !location.origin.includes("pgyerapps.com")
    )
};

export const redirectPrivate = async () => {
    try {
        const result: any = await getAppVersionDetail(location.href);
        console.log("redirectPrivate result", result);
        location.href = result.data;
        return Promise.reject();
    } catch (e) {
        alert("页面不存在");
        return Promise.reject();
    }
}
